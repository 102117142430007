<template>
  <div>
    <optionscard :deliverParentNum="4"></optionscard>
    <!-- 大图（场馆活动） -->
    <div class="bigPic">
      <img :src="imageTwo" class="bigPicInner" width="100%" height="100%" />
    </div>
    <div class="classifyDiv">
      <li
        @click="switchClassify(item.category_id)"
        :class="
          // activityarticle存放活动列表的参数
          // classifyList存放分类选项名字
          activityarticle.cate_id == item.category_id ? 'blue3' : 'white3'
        "
        v-for="item in classifyList"
        :key="item.category_id"
      >
        {{ item.name }}
      </li>
    </div>
    <div class="width1200">
      <!-- 全部、场馆体验、VR体验、科普讲座（分类列表） -->

      <!-- 大div（包着下面的东西） -->
      <div class="activity-div">
        <!-- 图片和名字  activityList存放活动内容数据-->
        <div
          class="activity-picture"
          v-for="item in activityList"
          :key="item.id"
        >
          <!-- 图片 -->
          <div class="activity-picture1" @click="getActivity(item.id)">
            <img :src="item.image" width="100%" height="100%" />
          </div>
          <div class="activity-body">
            <!-- 标题 +活动时间 -->
            <div class="titleDataDiv" @click="getActivity(item.id)">
              <div class="picture-title">
                {{ item.title }}
              </div>
              <!-- 活动时间 -->
              <div class="picture-data">
                活动时间：{{ item.start_time }} - {{ item.end_time }}
              </div>
            </div>
            <!-- 预约按钮 -->
            <div class="data-button">
              <div class="icon-button" @click="appointment(item.id, item.sort)">
                <i class="iconfont icon-yuyue"></i>
                预约
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination
        class="elPagination2"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="activityarticle.page"
        :page-sizes="[6, 12, 18, 24]"
        :page-size="activityarticle.paginate"
        layout="total, slot, prev, pager, next, jumper"
        :total="total"
      >
        <span class="paginationSpan"> 记录 </span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import optionscard from '@/components/optionscard.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard
  },
  data () {
    return {
      // 存放活动列表的参数
      activityarticle: {
        cate_id: null,
        paginate: 6,
        page: 1
      },
      // 存放分类选项的参数
      classifyArticleList: {
        pid: 4,
        paginate: 4
      },
      total: 0,
      // 分类封面
      imageTwo: '',
      // 存放活动内容数据
      activityList: [],
      // 存放广告图
      advertisingList: [],
      // 存放分类选项名字
      classifyList: []
    }
  },
  created () {
    this.getActivityList()
    this.getlist()
    this.getAdvertisingPic()
  },
  methods: {
    // 获取活动内容数据
    async getActivityList () {
      const { res } = await this.$api.venueactivity.getActivityList(
        this.activityarticle
      )
      console.log('res', res)
      for (const i of res.data) {
        // 去除时间后面的时分秒
        if (i.start_time.length > 10) {
          i.start_time = i.start_time.slice(0, 10)
        }
        if (i.end_time.length > 10) {
          i.end_time = i.end_time.slice(0, 10)
        }
      }
      this.activityList = res.data
      console.log('this.activityList', this.activityList)
      this.total = res.total
    },
    // 获取分类选项（全部  场馆体验  VR体验  科普讲座（并把‘全部’选项加在所有选项前面
    async getlist () {
      const { res } = await this.$api.venueactivity.getlist(
        this.classifyArticleList
      )
      console.log('res1', res)
      this.classifyList = [
        {
          category_id: null,
          name: '全部'
          /* ...res 是和‘全部’合并在一个数组  ‘全部’合并在前面 */
        },
        ...res
      ]
    },
    // 获取广告图片
    async getAdvertisingPic () {
      const { res } = await this.$api.venueactivity.getAdvertisingPic(
        this.advertisingList
      )
      console.log('res123', res)
      this.imageTwo = res.huodong[0].pic
    },
    // 切换分类
    switchClassify (id) {
      this.activityarticle.cate_id = id
      console.log('id', id)
      this.getActivityList()
    },
    // 跳转到文章详情
    getActivity (id) {
      this.$router.push({ path: '/forth/cghdfirst/cghdsecond', query: { id } })
    },

    // 预约按钮（跳转到cghdsecond的预约单）
    appointment (id, k) {
      this.$router.push({
        path: '/forth/cghdfirst/cghdsecond',
        query: { id, k }
      })
    },

    // 每页几条
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.activityarticle.paginate = val
      this.getActivityList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.activityarticle.page = val
      this.getActivityList()
    }
  }
}
</script>

<style lang="scss" scoped>
.bigPic {
  height: 245px;
}

.bigPicInner {
  object-fit: cover;
}

.classifyDiv {
  padding: 0 360px;
  background-color: #f7f7f7;
}

.width1200 {
  width: 1200px;
  margin: 0 auto;
}

.blue3 {
  display: inline-block;
  text-align: center;
  line-height: 60px;
  color: #2e88e4;
  padding: 0 20px;
  // margin: 0 20px;
  height: 55px;
  border-bottom: 4px solid #2e88e4;
  list-style: none;
  cursor: pointer;
  font-size: 16px;
}

.white3 {
  display: inline-block;
  text-align: center;
  line-height: 60px;
  padding: 0 20px;
  // margin: 0 20px;
  height: 55px;
  list-style: none;
  cursor: pointer;
  font-size: 16px;
}

.activity-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.activity-picture {
  border: solid 1px #eaeaea;
  width: 588px;
  height: 350px;
  margin: 30px 0 0px 0;
  transition: all 0.6s;
}

.activity-picture:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.activity-picture1 {
  width: 100%;
  height: 235px;
}

.activity-picture:hover .picture-title {
  color: #2e88e4;
}

.activity-body {
  border-top: 0;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.titleDataDiv {
  margin-left: 28px;
}

.picture-title {
  font-size: 20px;
  margin-top: 20px;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.picture-data {
  font-size: 14px;
  color: #999999;
  margin-top: 18px;
}

.data-button {
  display: flex;
  justify-content: space-between;
  margin-top: 49px;
  margin-right: 25px;
  font-size: 16px;
  width: 96px;
  color: #ffffff;
  height: 34px;
}

.icon-button {
  width: 96px;
  height: 34px;
  color: #fff;
  background-color: #2e88e4;
  text-align: center;
  line-height: 34px;
}

.elPagination2 {
  margin: 40px 0;
  text-align: right;
}

.paginationSpan {
  font-weight: normal;
  color: #606060;
}
</style>
